import {
  createBehaviorSubject,
  BehaviorSubject,
  BehaviorSubjectDispatcher
} from 'packages/behavior-subject';
import { SessionManagerInterface } from 'apps/acp/packages/session-manager';
import { LogoutLinks } from 'apps/acp/micro-frontends/logout';
import { isDynatrace } from 'apps/acp/packages/acp-dynatrace';
import { AuthorizationBlocks } from 'apps/acp/packages/webapi';
import { isWeb } from 'packages/platform-detector';
import { POST_REDIRECT_KEY } from './storage-keys';

// I provide capabilities for writing and reading the access token.
// access_token is encoded as base64 and stored as a JSON value for
// legacy reasons needed to be compatible with the AngularJS app.
// Exposing access token as a behavior subject so changes can be listened
// to and side-effects implemented (e. g. re-creating a fetcher).

export function sessionManagerBehaviorSubjectFactory(): BehaviorSubject<
  SessionManagerInterface
> {
  const KEY = 'acp_access_token';
  const PREONBOARDING_LOADED_KEY = 'acp_pre_onboarding_loaded';
  const AUTH_BLOCKS_KEY = 'acp_auth_blocks';
  const ngCookies = ['ACP_NG'];
  let initialAccessToken = '';
  let initialAuthBlocks: AuthorizationBlocks[] = [];
  let preOnboardingValue: string;
  try {
    const token = sessionStorage.getItem(KEY) || '';
    initialAccessToken = JSON.parse(atob(token));
    const authBlocks =
      sessionStorage.getItem(AUTH_BLOCKS_KEY) ||
      btoa(JSON.stringify(initialAuthBlocks));
    initialAuthBlocks = JSON.parse(atob(authBlocks));
  } catch (err) {
    // noop
  }
  const [dispatch, addListener] = createBehaviorSubject<
    SessionManagerInterface
  >({
    access_token: initialAccessToken,
    authorization_blocks: initialAuthBlocks
  });
  const writeAccessTokenAndDispatch: BehaviorSubjectDispatcher<SessionManagerInterface> = (
    sessionManager
  ) => {
    const deleteNGCookies = () => {
      ngCookies.forEach(function (cookie) {
        document.cookie =
          cookie + '=;Expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
      });
    };
    try {
      switch (sessionManager.event) {
        case 'logout':
          {
            /** RAINBOW-9665
             * pre-onboarding to be shown on mobile app only and until a
             * successful log in occurred on the device so going to other
             * pre-auth screens (contact us, etc) would not dismiss the
             * pre-onboarding from displaying again when the user opens
             * the app if they hadn't fully logged in before after it was
             * installed.
             */
            if (sessionStorage.getItem(PREONBOARDING_LOADED_KEY) && !isWeb()) {
              preOnboardingValue = JSON.parse(
                atob(sessionStorage.getItem(PREONBOARDING_LOADED_KEY) as string)
              );
            }
            sessionStorage.clear();
            if (!sessionStorage.getItem(PREONBOARDING_LOADED_KEY) && !isWeb()) {
              preOnboardingValue &&
                sessionStorage.setItem(
                  PREONBOARDING_LOADED_KEY,
                  btoa(preOnboardingValue)
                );
            }
            // pre-auth url should not be saved in post auth redirect key
            // RAINBOW-5673
            if (
              sessionManager.path &&
              !sessionManager.path.includes('/login')
            ) {
              //Getting path name in logout url if accessing authed MFE without accessToken so need to save that path in session storage.
              sessionStorage.setItem(
                POST_REDIRECT_KEY,
                btoa(JSON.stringify(sessionManager.path))
              );
            }

            //To maintain same code in both mfe and angular at logout.
            deleteNGCookies();

            //Ending dynntrace logging session
            if (window.dtrum && isDynatrace(window.dtrum)) {
              window.dtrum.endSession();
            }

            //redirecting to login url with or without query param which is being decided in logout component.
            history.replaceState(null, '', sessionManager.redirectUrl);
          }
          break;
        default: {
          if (sessionManager.access_token) {
            sessionStorage.setItem(
              KEY,
              btoa(JSON.stringify(sessionManager.access_token))
            );
          }
          if (sessionManager.authorization_blocks) {
            sessionStorage.setItem(
              AUTH_BLOCKS_KEY,
              btoa(JSON.stringify(sessionManager.authorization_blocks))
            );
          }
        }
      }
    } catch (err) {
      // noop
    }
    dispatch(sessionManager);
  };

  return [writeAccessTokenAndDispatch, addListener];
}

/**
 * I provide capabilities for writing and reading the expiration time.
 * acp_expiration_time is encoded as base64 and stored as a JSON value for
 * legacy reasons needed to be compatible with the AngularJS app.
 * Exposing acp_expiration_time as a behavior dispatcher so changes can be
 * to listened.
 */

export function sessionExpirationBehaviorSubjectDispatcherFactory(
  logout: LogoutLinks
): BehaviorSubjectDispatcher<string> {
  // read expiration from session storage at init
  // create setTime, then redirect to logout with reason timeout
  const KEY = 'acp_expiration_time';
  let initialSessionExpirationValue = '';
  try {
    const initialValue = sessionStorage.getItem(KEY) || '';
    initialSessionExpirationValue = JSON.parse(atob(initialValue));
  } catch {
    //noop
  }
  const [dispatch, addListener] = createBehaviorSubject(
    initialSessionExpirationValue
  );
  let timerHandler: any = null;

  addListener((expirationTime) => {
    if (timerHandler) {
      clearTimeout(timerHandler);
    }
    if (expirationTime) {
      sessionStorage.setItem(KEY, btoa(JSON.stringify(expirationTime)));
      const timeoutAfter = Number(expirationTime) - Date.now();

      /**
       * After timeout we need to modify current history entry
       * so used history.replaceState as it modifies current entry with
       * with null(stateObject),title and url passed in it.
       */
      timerHandler = setTimeout(() => {
        const path = location.pathname;
        const reason = 'session-timeout';

        /**
         * redirecting to logout url with path and reason which will
         * internally redirect to login url with query param as reason passed.
         * eg. logout/file-dispute/session-timeout -> login?reason=session-timeout.
         * The path is being saved in session storage and
         * reason is used for query param in login url.
         */
        history.replaceState(
          null,
          'Session timeout',
          logout.logoutWithPostAuthPathWithReason.url({
            path: encodeURIComponent(path),
            reason: reason
          })
        );
      }, timeoutAfter);
    }
  });
  return dispatch;
}

import { createElement } from 'react';
import { ErrorBoundary } from 'apps/acp/packages/error-boundary';
export const createReactErrorHandling = ({
  logoutLink,
  returnLink,
  appVersion
}) => ({
  children
}) => createElement(ErrorBoundary, {
  logoutLink: logoutLink,
  returnLink: returnLink,
  acpVersion: appVersion
}, children);
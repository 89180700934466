import {
  createBehaviorSubject,
  BehaviorSubject,
  BehaviorSubjectSubscribe,
  BehaviorSubjectDispatcher
} from 'packages/behavior-subject';
import {
  createFetcher,
  Fetcher,
  FetchImpl,
  FetcherRefreshPolicy
} from 'packages/http-client/fetcher';
import { AcpConfig, AcpEnvironment } from 'apps/acp/packages/acp-config';
import { AuthorizationBlocks } from 'apps/acp/packages/webapi';
import { AuthError } from 'apps/acp/packages/error-boundary';
import { SessionManagerInterface } from 'apps/acp/packages/session-manager';
import { bsGetValue } from 'packages/behavior-subject/fn';

// decorator: base url
// decorator: X-NS-Client header
// decorator: unfetch testing override
// decorator: access token
// query refetch check
// dispatch new fetcher on access token change

// I defined a middleware type for chain of responbility pattern
// with fetch (like express js middleware).
type FetcherDecorator = (next: FetchImpl) => FetchImpl;

// I am a type-safe pipe functional utility.
const pipe = <T extends any[], R>(
  fn1: (...args: T) => R,
  ...fns: Array<(a: R) => R>
) => {
  const piped = fns.reduce(
    (prevFn, nextFn) => (value: R) => nextFn(prevFn(value)),
    (value) => value
  );
  return (...args: T) => piped(fn1(...args));
};

// I take the ACP config and encode the value used in the request
// header for webapi.
function buildNsClientHeaderValue(
  config: AcpConfig,
  brand: string | undefined
) {
  const brandingInfo = brand ? brand : config.brand;
  return [
    `app=${config.appName}`,
    `platform=${config.platform}`,
    `platformType=${config.platformType}`,
    `brand=${brandingInfo}`,
    `version=${config.version}`
  ]
    .join('; ')
    .trim();
}

// I apply the base url to every request URL to reduce brittle api resource
// links throughout the app.
const withBaseUrl = (baseUrl: string): FetcherDecorator => (next) => (
  url,
  requestConfig
) => {
  const isCrossBrandRequest = (requestConfig.opts || ({} as any)).crossBrand;
  return next(isCrossBrandRequest ? url : baseUrl + url, requestConfig);
};

// I include the x-ns-client and x-ns-variant header in all requests with the passed config.
const withNsClientHeader = (config: AcpConfig): FetcherDecorator => (next) => (
  url,
  requestConfig
) => {
  /** https://bitbucket.hq.netspend.com/projects/APP/repos/ui-packages/pull-requests/2089/diff#apps/acp/micro-frontends/activate-card/implementation/app/fetcher.tsx?t=48
   *  Changed order/position of spread operator when header options are present.
   *  when we are making cross brand calls we want to update corresponding variant ID to
   *  header for that cross brand website which will be returned from API
   */
  const brand = (requestConfig.opts || ({} as any)).brand;
  return next(url, {
    ...requestConfig,
    headers: {
      'X-NS-Client': buildNsClientHeaderValue(config, brand),
      'X-NS-Variant': `variant://${config.variant}`,
      ...requestConfig.headers
    }
  });
};

// I include the access token if it exists in headers.
const withAccessToken = (
  accessToken: string,
  authBlocks: AuthorizationBlocks[]
): FetcherDecorator => (next) => (url, requestConfig) => {
  const requestOptions = requestConfig.opts || ({} as any);
  const isAuthBlockRequest = requestOptions.authBlocksRequest;
  const isHandoffAccessTokenRequest = requestOptions.handoffPurpose;
  const { ignorableAuthBlocks } = requestOptions;
  return next(url, {
    ...requestConfig,
    headers: Object.assign(
      {},
      requestConfig.headers,
      /* add access token only when either user has no authorization blocks
       or request is for auth blocks(ooba,idq,change password, set security questions) api's
       or the request declares a set of auth blocks that can be ignored, and the user only has those auth blocks
      */
      /**
       * isHandoffAccessTokenRequest is passed from branding fetcher and if its value is false then only
       * we will be sending access token.
       */
      accessToken &&
        !isHandoffAccessTokenRequest &&
        (authBlocks.length === 0 ||
          isAuthBlockRequest ||
          (ignorableAuthBlocks &&
            areAuthBlocksIgnorable(authBlocks, ignorableAuthBlocks)))
        ? {
            'X-NS-Access_Token': accessToken
          }
        : null
    )
  });
};

//I include the paypal_Risk_correlation_id if it exists in headers.
const withRiskCorrelationId = (rcid: string | null): FetcherDecorator => (
  next
) => (url, requestConfig) => {
  return next(url, {
    ...requestConfig,
    headers: Object.assign(
      {},
      requestConfig.headers,
      /* add paypal_Risk_correlation_id only when it is available in session storage only.*/
      rcid
        ? {
            'x-ns-paypal_correlation_id': atob(rcid)
          }
        : null
    )
  });
};

// I mock out fetch with an XHR implementation so Cypress can intercept
// requests.
const withMaybeMockFetch = (shouldMock: boolean): FetcherDecorator => (
  next
) => async (url, requestConfig) => {
  let fetchImpl = next;
  if (shouldMock) {
    const unfetch = await import('unfetch');
    fetchImpl = unfetch.default;
  }
  return fetchImpl(url, requestConfig);
};

const withIsAuthError = (): FetcherDecorator => (next) => async (
  url,
  requestConfig
) => {
  const response = await next(url, requestConfig);
  const canSkipAuthErrorValidation = (requestConfig.opts || ({} as any))
    .skipAuthErrorValidation;
  if (!canSkipAuthErrorValidation && !response.ok) {
    const { error: responseError } = await response.clone().json();
    const isAuthExpiredError = responseError === 'auth.expired';
    if (response.status === 401 && isAuthExpiredError) {
      throw new AuthError('expired', response);
    } else if (response.status === 401) {
      throw new AuthError('invalid', response);
    }
  }
  return response;
};

// I decide when fetcher queries should invalidate cache and refresh.
const fetcherRefreshPolicy: FetcherRefreshPolicy = (
  [completedRequest, completedRequestResponse],
  [otherRequest]
) => {
  const completedRequestOpts: any = completedRequest.opts || {};
  const otherRequestOpts: any = otherRequest.opts || {};
  const shouldInvalidate =
    !!completedRequest.mutating &&
    !!completedRequestResponse.ok &&
    !completedRequestOpts.beacon &&
    !completedRequestOpts.updatesAccessToken &&
    !otherRequestOpts.dontRerequest;

  return shouldInvalidate;
};

// I create the fetcher instance with applied decorators.
function createWebapiFetcher(
  accessToken: string,
  authBlocks: AuthorizationBlocks[],
  acpEnvironment: AcpEnvironment
): Fetcher {
  // Each request/response will be piped, top-down, through the decorators.
  const fetchDecorator = pipe(
    withMaybeMockFetch(localStorage.getItem('useFetchPolyfill') === 'yes'),
    withNsClientHeader(acpEnvironment.config),
    withAccessToken(accessToken, authBlocks),
    withBaseUrl('/webapi'),
    withIsAuthError(),
    withRiskCorrelationId(
      sessionStorage.getItem('acp_paypal.risk_correlation_id')
    )
  );
  return createFetcher(fetchDecorator(window.fetch), fetcherRefreshPolicy);
}

// I provide a mechanism for updating fetcher instance. This is valuable
// as it allows us to rely on busting cache by re-freshing the fetcher
// and triggering a full react render by updating context. This is very
// useful when changing authentication levels (i.e. logout).
export function webapiFetcherBehaviorSubjectFactory({
  acpEnvironment,
  accessTokenSubscribe,
  // dependency of SessionExpirationDispatcher is only for initialization
  // of the session expiration manager
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sessionExpirationDispatcher
}: {
  acpEnvironment: BehaviorSubjectSubscribe<AcpEnvironment>;
  accessTokenSubscribe: BehaviorSubjectSubscribe<SessionManagerInterface>;
  sessionExpirationDispatcher: BehaviorSubjectDispatcher<string>;
}): BehaviorSubject<Fetcher> {
  const initialFetcher = createWebapiFetcher(
    '',
    [],
    bsGetValue(acpEnvironment)
  );
  const [dispatch, addListener] = createBehaviorSubject(initialFetcher);
  accessTokenSubscribe((nextToken) => {
    acpEnvironment((nextEnv) => {
      const nextFetcher = createWebapiFetcher(
        nextToken.access_token,
        nextToken.authorization_blocks || [],
        nextEnv
      );
      dispatch(nextFetcher);
    });
  });

  return [dispatch, addListener];
}

// returns true if `authBlocks` contains no auth blocks other than elements of `ignorableAuthBlocks`
const areAuthBlocksIgnorable = (
  authBlocks: AuthorizationBlocks[],
  ignorableAuthBlocks: AuthorizationBlocks[]
) => !authBlocks.some((r) => !ignorableAuthBlocks.includes(r));

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BehaviorSubjectSubscribe,
  createBehaviorSubject
} from 'packages/behavior-subject';
import { Fetcher } from 'packages/http-client/fetcher';
import {
  createGetAuthenticationStatusRequest,
  AuthenticationStatusResponse
} from 'apps/acp/packages/webapi';

type AuthenticationStatusTuple = Readonly<
  [boolean, Error | null, AuthenticationStatusResponse | null]
>;

type AuthenticationResolver = (
  callback: (status: AuthenticationStatusTuple) => void
) => () => void;

function createWebapiAuthenticationResolver(fetcher: Fetcher) {
  return (callback: (result: AuthenticationStatusTuple) => void) => {
    const requestConfig = createGetAuthenticationStatusRequest();
    const [request, onUpdate] = fetcher(requestConfig);
    request(true);

    onUpdate(([loading, response, data, error]) => {
      if (error) {
        callback([false, error, null]);
      }
      if (!loading) {
        callback([false, null, data]);
      }
    });
    return () => {
      // noop
    };
  };
}

export function webapiAuthenticationResolverSubscribeFactory({
  fetcherSubscribe
}: {
  fetcherSubscribe: BehaviorSubjectSubscribe<Fetcher>;
}) {
  const [dispatch, subscribe] = createBehaviorSubject<AuthenticationResolver>(
    null as any
  );
  fetcherSubscribe((fetcher) => {
    const authenticationResolver = createWebapiAuthenticationResolver(fetcher);
    dispatch(authenticationResolver);
  });
  return subscribe;
}

export const createDeclerativeAuthenticationApi = (
  resolverSubscribe: BehaviorSubjectSubscribe<AuthenticationResolver>
) => (requestedObject: Partial<AuthenticationStatusResponse>) => {
  let unsubscribe: () => void;
  const promise = new Promise<boolean>((resolve, reject) => {
    const resolverUnsubscribe = resolverSubscribe((resolver) => {
      const requestedKeys = Object.keys(requestedObject) as Array<
        keyof AuthenticationStatusResponse
      >;
      unsubscribe = resolver(
        ([loading, error, authStatus]: AuthenticationStatusTuple) => {
          if (error) {
            resolve(false);
          }
          if (!loading && authStatus) {
            resolve(
              requestedKeys.every((key) => {
                if (key === 'authorization_blocks') {
                  const resolvedAuthBlocks =
                    authStatus.authorization_blocks || [];
                  const requestedAuthBlocks =
                    requestedObject.authorization_blocks;
                  return (
                    resolvedAuthBlocks.length === requestedAuthBlocks?.length &&
                    requestedAuthBlocks.every((blocks) =>
                      resolvedAuthBlocks.includes(blocks)
                    )
                  );
                }
                return authStatus[key] === requestedObject[key];
              })
            );
          }
        }
      );
    });
    resolverUnsubscribe();
  });

  return promise
    .then((result) => {
      unsubscribe();
      return result;
    })
    .catch((err) => {
      unsubscribe();
      return Promise.reject(err);
    });
};

import { CordovaGlobal } from 'apps/acp/packages/environment';
import { StatusBarGlobal } from 'apps/acp/packages/status-bar-plugin';

declare global {
  interface Window {
    StatusBar?: StatusBarGlobal;
  }
}
// Returns the StatusBarGlobal object if we're in a Cordova app
export const getStatusBarIfApplicable = (cordova?: CordovaGlobal) => {
  if (cordova) {
    return window.StatusBar as StatusBarGlobal;
  }
};

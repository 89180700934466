import { AcpEnvironment } from 'apps/acp/packages/acp-config';
import { GoogleTagManagerConfig } from 'apps/acp/gateway/packages/gtm';
import { AnalyticsEvent } from 'packages/react-analytics';
import { AdobeLaunchConfig } from 'apps/acp/gateway/packages/adobe-launch';

export const createGTMAnalyticsConfig = (
  acpEnvironment: AcpEnvironment
): GoogleTagManagerConfig => {
  const { gtmContainerId, brand, mode, variant } = acpEnvironment.config;
  return {
    gtmContainerId,
    brand,
    mode,
    variant
  };
};

export const createAdobeLaunchConfig = (
  acpEnvironment: AcpEnvironment,
  adobeLaunchScriptUrl: string
): AdobeLaunchConfig => {
  const { mode, variant } = acpEnvironment.config;
  return {
    adobeLaunchScriptUrl,
    mode,
    variant
  };
};

// calls sendAnalytics for both AdobeLaunch and GTM
export const createGTMAdobeLaunchSendAnalytics = (
  adobeLaunchSendAnalytics: (event: AnalyticsEvent) => void,
  gtmSendAnalytics: (event: AnalyticsEvent) => void
) => {
  return (event: AnalyticsEvent) => {
    adobeLaunchSendAnalytics(event);
    gtmSendAnalytics(event);
  };
};

export const checkCookieExist = (name: string) => {
  // Find the cookie
  const match = document.cookie.match(
    RegExp('(?:^|;\\s*)' + name + '=([^;]*)')
  );
  return match ? true : false;
};

export const deleteCookie = (name: string) => {
  document.cookie = name + '=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
};

export const ACP_Next_Cookie = 'ACP_Next';

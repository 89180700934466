
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.Container_052477068d27a23e{display:flex;flex-direction:column;position:absolute;top:0;left:0;width:100vw;min-height:100vh}`;
styleInject(_css)

/** Container Props */
export type ContainerCCM = {
  /** Container Component Custom Properties */
  // No custom properties found

  /** Container Modifier Flags */
  // No modifiers classes found
};
/** Base Container component */
export const Container: ComponentCreator<ContainerCCM> = createComponentCreator({
  "name": "Container",
  "base": "Container_052477068d27a23e",
  "prop": {},
  "mod": {}
});


import { Platform, getCurrentPlatform } from 'packages/platform-detector';
import { CordovaGlobal } from 'apps/acp/packages/environment';

export const isCordova = () =>
  [Platform.AndroidCordova, Platform.iOSCordova].includes(getCurrentPlatform());

// Returns the CordovaGlobal object when it's ready, if we're in a Cordova app
export const getCordovaIfApplicable = async () => {
  if (isCordova()) {
    await new Promise((resolve) =>
      document.addEventListener('deviceready', resolve)
    );

    return window.cordova as CordovaGlobal;
  }
};

//
// Declare all MFEs
//
import { declareMicroFrontend, baseUrl } from 'packages/gateway/gateway';
import { ActivateCardLinks } from 'apps/acp/micro-frontends/activate-card';
import { CryptoLinks } from 'apps/acp/micro-frontends/crypto';
import { LoginLinks } from 'apps/acp/micro-frontends/login';
import { LeanDisputeLinks } from 'apps/acp/micro-frontends/lean-dispute';
import { DashboardLinks } from 'apps/acp/micro-frontends/dashboard';
import { ManageAccountLinks } from 'apps/acp/micro-frontends/manage-account';
import { SpendTrackerLinks } from 'apps/acp/micro-frontends/spend-tracker';
import { MoveMoneyLinks } from 'apps/acp/micro-frontends/move-money';
import { SpendingAccountLinks } from 'apps/acp/micro-frontends/spending-account';
import { DirectDepositLinks } from 'apps/acp/micro-frontends/direct-deposit';
import { ManageLinks } from 'apps/acp/micro-frontends/manage';
import { CloseAccountLinks } from 'apps/acp/micro-frontends/close-account';
import { CardLinks } from 'apps/acp/micro-frontends/card';
import { ContactLinks } from 'apps/acp/micro-frontends/contact';
import { DynamicFAQLinks } from 'apps/acp/micro-frontends/dynamic-faq';
import { FeedbackLinks } from 'apps/acp/micro-frontends/feedback';
import { AnytimeAlertsLinks } from 'apps/acp/micro-frontends/anytime-alerts';
import { AnytimeAlertsUpgradeLinks } from 'apps/acp/micro-frontends/anytime-alerts-upgrade';
import { OverdraftLinks } from 'apps/acp/micro-frontends/overdraft';
import { BenefitCenterLinks } from 'apps/acp/micro-frontends/benefit-center';
import { LogoutLinks } from 'apps/acp/micro-frontends/logout';
import { LocationsLinks } from 'apps/acp/micro-frontends/locations';
import { SendReceiveMoneyLinks } from 'apps/acp/micro-frontends/send-receive-money';
import { CheckDepositLinks } from 'apps/acp/micro-frontends/check-deposit';
import { AtmsLinks } from 'apps/acp/micro-frontends/atms';
import { SavingsLinks } from 'apps/acp/micro-frontends/savings';
import { NotificationsLinks } from 'apps/acp/micro-frontends/notifications';
import { MclLinks } from 'apps/acp/micro-frontends/mcl';
import { WUTransfersLinks } from 'apps/acp/micro-frontends/wu-transfers';
import { PaypalTransfersLinks } from 'apps/acp/micro-frontends/paypal-transfers';
import { BankTransfersLinks } from 'apps/acp/micro-frontends/bank-transfers';
import { Me2MeTransfersLinks } from 'apps/acp/micro-frontends/me2me-transfers';
import { PayBillsLinks } from 'apps/acp/micro-frontends/pay-bills';
import { MoneygramLinks } from 'apps/acp/micro-frontends/moneygram';
import { SecureInboxLinks } from 'apps/acp/micro-frontends/secure-inbox';
import { ChecksLinks } from 'apps/acp/micro-frontends/checks';
import { SecurityLinks } from 'apps/acp/micro-frontends/security';
import { DirectDepositTrackerLinks } from 'apps/acp/micro-frontends/direct-deposit-tracker';
import { AtmFinderLinks } from 'apps/acp/micro-frontends/atm-finder';
import { RecoverLinks } from 'apps/acp/micro-frontends/recover';
import { FeeplanLinks } from 'apps/acp/micro-frontends/feeplan';
import { UpgradeAccountLinks } from 'apps/acp/micro-frontends/upgrade-account';
import { ReferAFriendLinks } from 'apps/acp/micro-frontends/refer-a-friend';
import { ExternalLoyaltyLinks } from 'apps/acp/micro-frontends/external-loyalty';
import { LoyaltyLinks } from 'apps/acp/micro-frontends/loyalty';
import { LoyaltyLearnMoreLinks } from 'apps/acp/micro-frontends/loyalty-learn-more';
import { PaypalTransfersInfoLinks } from 'apps/acp/micro-frontends/paypal-transfers-info';

import { DigitalWalletLinks } from 'apps/acp/micro-frontends/digital-wallet';
import { SpeedyRewardsLinks } from 'apps/acp/micro-frontends/speedy-rewards';
import { AlbertsonsDollarsLinks } from 'apps/acp/micro-frontends/albertsons-dollars';
import { DebitCardTransferFundsLinks } from 'apps/acp/micro-frontends/debit-card-transfer-funds';
import { ConnectedBanksLinks } from 'apps/acp/micro-frontends/connected-banks';
import { OffersLinks } from 'apps/acp/micro-frontends/offers';
import { CashRewardsLink } from 'apps/acp/micro-frontends/cashrewards';
import { DirectOffersLink } from 'apps/acp/micro-frontends/direct-offers';
import { SkylightChecksLinks } from 'apps/acp/micro-frontends/skylight-checks';
import { InterstitialsLinks } from 'apps/acp/micro-frontends/interstitials';
import { PreOnboardingLinks } from 'apps/acp/micro-frontends/pre-onboarding';
import { CashbackLinks } from 'apps/acp/micro-frontends/cashback';
import { DisputeHubLink } from '../micro-frontends/dispute-hub-tracker';
import { SecureUploadLinks } from 'apps/acp/micro-frontends/secure-upload';
import { RentTrackLinks } from 'apps/acp/micro-frontends/rent-track';
import { PaypalAuthLinks } from 'apps/acp/micro-frontends/paypal-rest-auth';
import { HandOffLinks } from 'apps/acp/micro-frontends/handoff';
import { LinkExternalAccountSuccessLinks } from 'apps/acp/micro-frontends/link-external-account-success';
import { DormantAccountLink } from 'apps/acp/micro-frontends/dormant-account';
import { PremierPlanLinks } from 'apps/acp/micro-frontends/premier-plan';
import { UploadAdditionalDocumentsLinks } from 'apps/acp/micro-frontends/upload-additional-documents';
import { SelfEnrollmentLinks } from 'apps/acp/micro-frontends/self-enrollment';
import { EsignLinks } from 'apps/acp/micro-frontends/esign';
import { FraudDetectionLinks } from 'apps/acp/micro-frontends/fraud-detection';
import { SecureCreditCardLinks } from 'apps/acp/micro-frontends/secure-credit-card';

export const activateCard = declareMicroFrontend<ActivateCardLinks>(
  'activation',
  baseUrl.extend`/activate`()
);

export const login = declareMicroFrontend<LoginLinks>(
  'login',
  baseUrl.extend`/login`()
);

export const paypalAuthenticate = declareMicroFrontend<PaypalAuthLinks>(
  'paypal-authenticate',
  baseUrl.extend`/paypal/authenticate`()
);

export const register = declareMicroFrontend<ActivateCardLinks>(
  'register',
  baseUrl.extend`/register`()
);

export const crypto = declareMicroFrontend<CryptoLinks>(
  'crypto',
  baseUrl.extend`/crypto`()
);

export const leanDispute = declareMicroFrontend<LeanDisputeLinks>(
  'lean-dispute',
  baseUrl.extend`/file-dispute`()
);

export const dashboard = declareMicroFrontend<DashboardLinks>(
  'dashboard',
  baseUrl.extend`/dashboard`()
);

export const spendTracker = declareMicroFrontend<SpendTrackerLinks>(
  'spending-tracker',
  baseUrl.extend`/spending-tracker`()
);

export const moveMoney = declareMicroFrontend<MoveMoneyLinks>(
  'move-money',
  baseUrl.extend`/move-money-menu`()
);

export const spendingAccount = declareMicroFrontend<SpendingAccountLinks>(
  'spending-account',
  baseUrl.extend`/spending-account`()
);

export const directDeposit = declareMicroFrontend<DirectDepositLinks>(
  'direct-deposit',
  baseUrl.extend`/direct-deposit`()
);

export const manageAccount = declareMicroFrontend<ManageAccountLinks>(
  'your-account',
  baseUrl.extend`/your-account`()
);

export const manage = declareMicroFrontend<ManageLinks>(
  'manage',
  baseUrl.extend`/manage`()
);

export const card = declareMicroFrontend<CardLinks>(
  'card',
  baseUrl.extend`/card`()
);

export const closeAccount = declareMicroFrontend<CloseAccountLinks>(
  'close-account',
  baseUrl.extend`/close-account`()
);

export const contact = declareMicroFrontend<ContactLinks>(
  'contact',
  baseUrl.extend`/contact`()
);

export const dormantAccount = declareMicroFrontend<DormantAccountLink>(
  'dormant-account',
  baseUrl.extend`/dormant-account`()
);

export const dynamicFAQ = declareMicroFrontend<DynamicFAQLinks>(
  'dynamic-faq',
  baseUrl.extend`/dynamic-faq`()
);

export const feedback = declareMicroFrontend<FeedbackLinks>(
  'feedback',
  baseUrl.extend`/feedback`()
);

export const logout = declareMicroFrontend<LogoutLinks>(
  'logout',
  baseUrl.extend`/logout`()
);

export const benefitCenter = declareMicroFrontend<BenefitCenterLinks>(
  'benefit-center',
  baseUrl.extend`/benefit-center`()
);

export const anytimeAlerts = declareMicroFrontend<AnytimeAlertsLinks>(
  'anytime-alerts',
  baseUrl.extend`/anytime-alerts`()
);

export const anytimeAlertsUpgrade = declareMicroFrontend<
  AnytimeAlertsUpgradeLinks
>('anytime-alerts-upgrade', baseUrl.extend`/aa-upgrade`());

export const overdraft = declareMicroFrontend<OverdraftLinks>(
  'overdraft',
  baseUrl.extend`/overdraft`()
);

export const locations = declareMicroFrontend<LocationsLinks>(
  'locations',
  baseUrl.extend`/locations`()
);

export const offers = declareMicroFrontend<OffersLinks>(
  'offers',
  baseUrl.extend`/offers`()
);

export const cashRewards = declareMicroFrontend<CashRewardsLink>(
  'cashrewards',
  baseUrl.extend`/cash-rewards`()
);

export const directOffers = declareMicroFrontend<DirectOffersLink>(
  'directOffers',
  baseUrl.extend`/direct-offers`()
);

export const speedyRewards = declareMicroFrontend<SpeedyRewardsLinks>(
  'speedy-rewards',
  baseUrl.extend`/speedy-rewards`()
);

export const albertsonsDollars = declareMicroFrontend<AlbertsonsDollarsLinks>(
  'albertsons-dollars',
  baseUrl.extend`/points-learn-more`()
);

export const sendReceiveMoney = declareMicroFrontend<SendReceiveMoneyLinks>(
  'send-receive-money',
  baseUrl.extend`/move-money/contact`()
);

export const atms = declareMicroFrontend<AtmsLinks>(
  'atms',
  baseUrl.extend`/atms`()
);

export const savings = declareMicroFrontend<SavingsLinks>(
  'savings',
  baseUrl.extend`/savings`()
);

export const notifications = declareMicroFrontend<NotificationsLinks>(
  'notifications',
  baseUrl.extend`/notifications`()
);

export const mcl = declareMicroFrontend<MclLinks>(
  'mcl',
  baseUrl.extend`/mcl`()
);

export const checkDeposit = declareMicroFrontend<CheckDepositLinks>(
  'check-deposit',
  baseUrl.extend`/move-money/check-deposit`()
);

export const me2meTransfers = declareMicroFrontend<Me2MeTransfersLinks>(
  'me2me-transfers',
  baseUrl.extend`/me-to-me-transfer`()
);

export const bankTransfers = declareMicroFrontend<BankTransfersLinks>(
  'bank-transfers',
  baseUrl.extend`/bank-transfers`()
);

export const paypalTransfers = declareMicroFrontend<PaypalTransfersLinks>(
  'paypal-transfers',
  baseUrl.extend`/paypal-transfers`()
);

export const paypalTransfersInfo = declareMicroFrontend<
  PaypalTransfersInfoLinks
>('paypal-transfers-info', baseUrl.extend`/paypal-transfers-info`());

export const wuTransfers = declareMicroFrontend<WUTransfersLinks>(
  'wu-transfers',
  baseUrl.extend`/move-money/western-union`()
);

export const payBills = declareMicroFrontend<PayBillsLinks>(
  'pay-bills',
  baseUrl.extend`/pay-bills`()
);

export const moneygram = declareMicroFrontend<MoneygramLinks>(
  'moneygram',
  baseUrl.extend`/money-gram`()
);

export const checks = declareMicroFrontend<ChecksLinks>(
  'checks',
  baseUrl.extend`/checks`()
);

export const secureInbox = declareMicroFrontend<SecureInboxLinks>(
  'secure-inbox',
  baseUrl.extend`/secure-inbox`()
);

export const security = declareMicroFrontend<SecurityLinks>(
  'security',
  baseUrl.extend`/security`()
);

export const directDepositTracker = declareMicroFrontend<
  DirectDepositTrackerLinks
>('direct-deposit-tracker', baseUrl.extend`/dd-tracker`());

export const atmFinder = declareMicroFrontend<AtmFinderLinks>(
  'atm-finder',
  baseUrl.extend`/atm-finder`()
);

export const recover = declareMicroFrontend<RecoverLinks>(
  'recover',
  baseUrl.extend`/recover`()
);

export const locationFinder = declareMicroFrontend<LocationsLinks>(
  'location-finder',
  baseUrl.extend`/location-finder`()
);

export const feeplan = declareMicroFrontend<FeeplanLinks>(
  'feeplan',
  baseUrl.extend`/feeplan`()
);

export const upgradeAccount = declareMicroFrontend<UpgradeAccountLinks>(
  'upgrade-account',
  baseUrl.extend`/upgrade`()
);

export const referAFriend = declareMicroFrontend<ReferAFriendLinks>(
  'refer-a-friend',
  baseUrl.extend`/refer-a-friend`()
);

export const loyalty = declareMicroFrontend<LoyaltyLinks>(
  'loyalty',
  baseUrl.extend`/points`()
);

export const loyaltyLearnMore = declareMicroFrontend<LoyaltyLearnMoreLinks>(
  'loyalty-learn-more',
  baseUrl.extend`/points-learn-more`()
);

/** it's a one-off for GiantEagle, and is not related to the Loyalty Platform */
export const externalLoyalty = declareMicroFrontend<ExternalLoyaltyLinks>(
  'external-loyalty',
  baseUrl.extend`/external-loyalty`()
);

export const digitalWallet = declareMicroFrontend<DigitalWalletLinks>(
  'digital-wallet',
  baseUrl.extend`/digital-wallet`()
);

export const connectedBanks = declareMicroFrontend<ConnectedBanksLinks>(
  'connectedBanks',
  baseUrl.extend`/connected-banks`()
);

export const debitCardTransferFunds = declareMicroFrontend<
  DebitCardTransferFundsLinks
>('transfer-funds', baseUrl.extend`/transfer-funds`());

export const skylightChecks = declareMicroFrontend<SkylightChecksLinks>(
  'skylightChecks',
  baseUrl.extend`/skylight-checks`()
);

export const premierPlan = declareMicroFrontend<PremierPlanLinks>(
  'premierPlan',
  baseUrl.extend`/premier`()
);

export const interstitials = declareMicroFrontend<InterstitialsLinks>(
  'interstitials',
  baseUrl.extend`/interstitials`()
);

export const preOnboarding = declareMicroFrontend<PreOnboardingLinks>(
  'pre-onboarding',
  baseUrl.extend`/pre-onboarding`()
);

export const cashback = declareMicroFrontend<CashbackLinks>(
  'cashback',
  baseUrl.extend`/cashback`()
);

export const disputeHubTracker = declareMicroFrontend<DisputeHubLink>(
  'disputeHubTracker',
  baseUrl.extend`/dispute-hub`()
);

export const secureUpload = declareMicroFrontend<SecureUploadLinks>(
  'secure-upload',
  baseUrl.extend`/secure-upload`()
);

export const rentTrack = declareMicroFrontend<RentTrackLinks>(
  'rent-track',
  baseUrl.extend`/rent-track`()
);

export const handOff = declareMicroFrontend<HandOffLinks>(
  'handoff',
  baseUrl.extend`/handoff`()
);

export const linkExternalAccountSuccess = declareMicroFrontend<
  LinkExternalAccountSuccessLinks
>('link-external-account-success', baseUrl.extend`/external-account`());

export const uploadAdditionalDocuments = declareMicroFrontend<
  UploadAdditionalDocumentsLinks
>(
  'upload-additional-documents',
  baseUrl.extend`/upload-additional-documents`()
);

export const selfEnrollment = declareMicroFrontend<SelfEnrollmentLinks>(
  'self-enrollment',
  baseUrl.extend`/self-enrollment`()
);

export const esign = declareMicroFrontend<EsignLinks>(
  'esign',
  baseUrl.extend`/esign`()
);

export const fraudDetection = declareMicroFrontend<FraudDetectionLinks>(
  'fraud-detection',
  baseUrl.extend`/fraud-detection`()
);

export const secureCreditCard = declareMicroFrontend<SecureCreditCardLinks>(
  'secure-credit-card',
  baseUrl.extend`/secure-credit-card`()
);

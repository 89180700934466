import { isIOS, isAndroid } from 'packages/platform-detector';
import { Platform } from 'apps/acp/packages/acp-config';

export const derivePlatform = (): Platform => {
  if (isIOS()) {
    return 'ios';
  }
  if (isAndroid()) {
    return 'android';
  }
  return 'web';
};

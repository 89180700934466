import { start, registerGatewayDebugger } from 'packages/gateway/gateway';
import { setPrivateAcpEnvOnce } from './environment';
import { contentFiltersListener } from './services';
import './implementations';
import { AcpEnvironment } from 'apps/acp/packages/acp-config';
import { initializePGMListener } from 'apps/acp/packages/previous-gateway-mount';

declare global {
  interface Navigator {
    splashscreen?: {
      hide: () => void;
    };
  }
}

if (process.env.NODE_ENV !== 'production') {
  registerGatewayDebugger();
}

export const startGateway = (env: AcpEnvironment) => {
  setPrivateAcpEnvOnce(env);
  // This is not a pattern to follow, but is how we would
  // initiate a "process" that is not tied to a MFE but
  // managed as an injectable. Don't replicated this
  contentFiltersListener.load();
  start(history, location);
  /**
   * As opening mobile app on android or ios where mfe
   * login is enabled, the mfe login page get loaded but
   * the splashscreen is needed to be hide otherwise
   * the splashscreen is always visible.
   */
  document.addEventListener('deviceready', () => {
    if (navigator && navigator.splashscreen) navigator.splashscreen?.hide();
  });
  initializePGMListener();
};

import { Fetcher, RequestConfig } from 'packages/http-client/fetcher';
import {
  createFetcherPermissionsResolver,
  PermissionResolver
} from 'packages/permissions/permission-resolver';
import {
  createBehaviorSubject,
  BehaviorSubjectSubscribe
} from 'packages/behavior-subject';

const createWebapiFeatureRequest = (features: string[]): RequestConfig => ({
  method: 'POST',
  url: '/v2/features',
  body: JSON.stringify({
    features
  }),
  headers: {
    'content-type': 'application/json'
  }
});

function createWebapiPermissionResolver(fetcher: Fetcher) {
  return createFetcherPermissionsResolver(
    fetcher,
    createWebapiFeatureRequest,
    (response: Response, data: any) => {
      if (data && data.features) {
        return data.features;
      }

      return {};
    },
    (feature: string) => feature.toLowerCase()
  );
}

export function webapiPermissionResolverSubscribeFactory({
  fetcherSubscribe
}: {
  fetcherSubscribe: BehaviorSubjectSubscribe<Fetcher>;
}) {
  const [dispatch, subscribe] = createBehaviorSubject<
    PermissionResolver<string>
  >(null as any);
  fetcherSubscribe((fetcher) => {
    const permissionResolver = createWebapiPermissionResolver(fetcher);
    dispatch(permissionResolver);
  });

  return subscribe;
}

export const createDeclarativePermissionApi = <P extends string>(
  resolverSubscribe: BehaviorSubjectSubscribe<PermissionResolver<string>>
) => <R extends P>(permissions: { [perm in R]: boolean }) => {
  let unsubscribe: () => void;
  const promise = new Promise<boolean>((resolve, reject) => {
    const resolverUnsubscribe = resolverSubscribe((resolver) => {
      const permissionNames = Object.keys(permissions) as R[];
      unsubscribe = resolver(
        permissionNames,
        ([loading, error, resolvedPermissions]) => {
          if (error) {
            reject(error);
          }
          if (!loading && resolvedPermissions) {
            resolve(
              permissionNames.every(
                (perm) => resolvedPermissions[perm] === permissions[perm]
              )
            );
          }
        }
      );
    });
    resolverUnsubscribe();
  });

  return promise
    .then((result) => {
      unsubscribe();
      return result;
    })
    .catch((err) => {
      unsubscribe();
      return Promise.reject(err);
    });
};
